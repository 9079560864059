import { useState } from "react";
import Link from "next/link";
import { ProductRating } from "../Product";
import { useRouter } from 'next/router'

const ProductGridFive = ({
  product,
  discountedPrice,
  productPrice,
  cartItem,
  bottomSpace,
  addToCart,
  addToast,
  cartItems,
  sliderClass
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [colorImage, setColorImage] = useState("");
  const router = useRouter();

  return (
    <>
      <div
        className={`${sliderClass ? sliderClass : ""} ${
          bottomSpace ? bottomSpace : ""
        }`}
      >
        <div className="product-grid product-grid--style-three">
          <div className="product-grid__image"                     onClick={() => router.push("/shop/products/" + product.slug)}
 style={{
            backgroundImage:`url('${product.thumbImage[0]}')`,
            height: '250px',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}>
            {/* <Link
              href={`/shop/products/[slug]?slug=${product.slug}`}
              as={"/shop/products/" + product.slug}
            >
              <a> */}
                {/* <img
                  src={colorImage ? colorImage : product.thumbImage[0]}
                  alt="product_img1"
                />
                {product.thumbImage[1] && <img
                  className="product-hover-image"
                  src={colorImage ? colorImage : product.thumbImage[1]}
                  alt="product_img1"
                />} */}
              {/* </a>
            </Link> */}
            <div className="product-grid__badge-wrapper">
              {product.new ? <span className="pr-flash">NEW</span> : ""}
              {product.featured ? (
                <span className="pr-flash bg-danger">HOT</span>
              ) : (
                ""
              )}
              {product.discount ? (
                <span className="pr-flash bg-success">SALE</span>
              ) : (
                ""
              )}
            </div>
            <div className="product-grid__action-box">
              <ul>
                <li>
                  {/* {product.affiliateLink ? (
                    <a href={product.affiliateLink} target="_blank">
                      <i className="icon-action-redo" />
                    </a>
                  ) : product.variation && product.variation.length >= 1 ? (
                    <Link
                      href={`/shop/products/[slug]?slug=${product.slug}`}
                      as={"/shop/products/" + product.slug}
                    >
                      <a>
                        <i className="icon-wrench" />
                      </a>
                    </Link>
                  ) : product.stock && product.stock > 0 ? (
                    <button
                      onClick={() => router.push("/shop/products/" + product.slug)}
                      disabled={
                        cartItem !== undefined &&
                        cartItem.quantity >= cartItem.stock
                      }
                      className={cartItem !== undefined ? "active" : ""}
                    >
                      <i className="icon-basket-loaded" />
                    </button>
                  ) : (
                    <button disabled>
                      <i className="icon-basket-loaded" />
                    </button>
                  )} */}
                </li>
                <li>
                  {/* <button
                    onClick={() => router.push("/shop/products/" + product.slug)}
                    className="d-none d-lg-block"
                  >
                    <i className="icon-magnifier-add" />
                  </button> */}
                </li>
              </ul>
            </div>
          </div>
          <div className="product-grid__info">
            <h6 className="product-title">
              <Link
                href={`/shop/products/[slug]?slug=${product.slug}`}
                as={"/shop/products/" + product.slug}
              >
                <a>{product.name}</a>
              </Link>
            </h6>
            <div className="product-price">
              {product.discount ? (
                <>
                  <span className="price">£{discountedPrice}</span>
                  <del>£{productPrice}</del>
                  <span className="on-sale">{product.discount}% Off</span>
                </>
              ) : (
                <span className="price">£{productPrice}</span>
              )}
            </div>
            <div className="rating-wrap">
              <ProductRating ratingValue={product.rating} />
              <span className="rating-num">({product.ratingCount})</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductGridFive;
