import Link from "next/link";
import { ProductRating } from "../Product";

const ProductGridSix = ({
  product,
  discountedPrice,
  productPrice,
  cartItem,
  bottomSpace,
  addToCart,
  addToast,
  cartItems,
  sliderClass,
  isCustomPrice,
}) => {
  return (
    <>
      <div
        className={`${sliderClass ? sliderClass : ""} ${
          bottomSpace ? bottomSpace : ""
        }`}
      >
        <Link href={`/shop/products/${product.slug}`}>
          <div className="product-grid product-grid--style-three">
            <div className="product-grid__image">
              <img
                src={product.thumbImage[0]}
                alt={product.name}
                style={{
                  height: "17.75em",
                  width: "100%",
                }}
                loading="lazy"
              />
            </div>
            <div className="product-grid__badge-wrapper">
              {product.new ? <span className="pr-flash">NEW</span> : ""}
              {product.featured ? (
                <span className="pr-flash bg-danger">HOT</span>
              ) : (
                ""
              )}
              {product.discount ? (
                <span className="pr-flash bg-success">SALE</span>
              ) : (
                ""
              )}
            </div>
            <div className="product-grid__info">
              <h6 className="product-title">
                <Link href={`/shop/products/${product.slug}`}>
                  {product.name}
                </Link>
              </h6>
              <div className="product-price">
                {product.discount ? (
                  <>
                    <span className="price">£{discountedPrice.toFixed(2)}</span>
                    <del>£{productPrice.toFixed(2)}</del>
                    <span className="on-sale">
                      {product.discount.toFixed(2)}% Off
                    </span>
                  </>
                ) : (
                  <span
                    className="price"
                    style={
                      isCustomPrice ? { color: "black", fontWeight: 800 } : {}
                    }
                  >
                    £{productPrice.toFixed(2)}
                  </span>
                )}
              </div>
              {/* <div className='rating-wrap'>
              <ProductRating ratingValue={product.rating} />
              <span className='rating-num'>({product.ratingCount})</span>
            </div> */}
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default ProductGridSix;
