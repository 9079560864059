import { Row, Col } from "react-bootstrap";
import { ProductGridWrapperFive } from "../ProductThumb";

const ProductSliderTwo = ({ title, products, items }) => (
    <div className="product-slider-area space-pt--50">
      <Row>
        <Col md={6}>
          <div className="section-title space-mb--25">
            <h2>{title}</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="product-slider-wrap">
              <ProductGridWrapperFive
                products={products}
                items={items}
                bottomSpace="space-mb--30"
              />
          </div>
        </Col>
      </Row>
    </div>
  );

export default ProductSliderTwo;
