import Link from 'next/link'
import { IoIosFlash } from 'react-icons/io'
import { ProductGridWrapperSix } from '../ProductThumb'

const ProductSliderTwelve = ({ title, products }) => (
  <div className='product-slider-area space-pt--60 space-pb--r80'>
    <div className='custom-container'>
      <div className='section-title section-title--style-two heading-s1 d-flex justify-content-between align-items-center space-mb--30'>
        <h4>{title}</h4>
        <Link href='/shop/products'>
          <a className='text-default'>
            {' '}
            <IoIosFlash /> View All
          </a>
        </Link>
      </div>
      <div className='product-slider-wrap product-slider-wrap--custom-bullet'>
        <ProductGridWrapperSix
          products={products}
          bottomSpace='space-mb--30'
        />
      </div>
    </div>
  </div>
)

export default ProductSliderTwelve
