import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { SwiperSlide, Swiper } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { getDiscountPrice } from "../../lib/product";
import { addToCart } from "../../redux/actions/cartActions";
import ProductGridSix from "./ProductGridSix";
import { calculateCurrentPrice } from "../../lib/variation";
import { useSelector } from "react-redux";
SwiperCore.use([Navigation, Pagination]);

const ProductGridWrapperSix = ({
  products,
  bottomSpace,
  addToCart,
  cartItems,
}) => {
  const { addToast } = useToasts();
  const userData = useSelector((state) => state.userData.profileData);
  return (
    <Swiper
      spaceBetween={30}
      slidesPerView={4}
      slidesPerColumn={6}
      slidesPerColumnFill="row"
      grabCursor
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        1024: {
          slidesPerView: 5,
          slidesPerGroup: 5,
          slidesPerColumn: 6,
        },
        769: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          slidesPerColumn: 6,
        },
        576: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          slidesPerColumn: 6,
        },
        320: {
          slidesPerView: 1,
          slidesPerGroup: 1,
          slidesPerColumn: 6,
        },
      }}
    >
      {products &&
        products
          .filter((product) => product.approved)
          .map((product) => {
            const cartItem = cartItems.filter(
              (cartItem) => cartItem.id === product.id
            )[0];

            const { price } = calculateCurrentPrice(product.variations[0], 1);
            //Todo Implement discounted pricing
            const discountedPrice = price;
            let isCustomPrice = false;
            if (
              userData?.productCustomPrices &&
              userData.productCustomPrices[product.id]
            ) {
              price = userData.productCustomPrices[product.id].price;
              isCustomPrice = true;
            }
            return (
              <SwiperSlide key={product.id}>
                <ProductGridSix
                  product={product}
                  discountedPrice={discountedPrice}
                  productPrice={price}
                  cartItem={cartItem}
                  bottomSpace={bottomSpace}
                  addToCart={addToCart}
                  addToast={addToast}
                  cartItems={cartItems}
                  isCustomPrice={isCustomPrice}
                />
              </SwiperSlide>
            );
          })}
    </Swiper>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize
        )
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductGridWrapperSix);
