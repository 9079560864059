import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { getDiscountPrice } from "../../lib/product";
import { addToCart } from "../../redux/actions/cartActions";
import ProductGridTwo from "./ProductGridTwo";

const ProductGridWrapperTwo = ({
  products,
  bottomSpace,
  addToCart,
  cartItems,
  sliderClass,
}) => {
  const { addToast } = useToasts();
  return (
    <>
      {products &&
        products.map((product) => {
          const discountedPrice = getDiscountPrice(
            product.price,
            product.discount
          ).toFixed(2);
          let productPrice = product.price;
          product.price >= 1000000
            ? (productPrice = `${(productPrice / 1000000).toLocaleString()} M`)
            : (productPrice = productPrice.toFixed(2));
          const cartItem = cartItems.filter(
            (cartItem) => cartItem.id === product.id
          )[0];

          return (
            <ProductGridTwo
              key={product.id}
              product={product}
              discountedPrice={discountedPrice}
              productPrice={productPrice}
              cartItem={cartItem}
              bottomSpace={bottomSpace}
              addToCart={addToCart}
              addToast={addToast}
              cartItems={cartItems}
              sliderClass={sliderClass}
            />
          );
        })}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData.cart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize
        )
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductGridWrapperTwo);
