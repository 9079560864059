// get products
export const getProducts = (products, category, type, limit) => {
  const finalProducts = category
    ? products.filter((product) => product.category === category)
    : products;

  switch (type) {
    case "featured":
      const featuredProducts = finalProducts.filter(
        (single) => single.featured
      );
      return featuredProducts.slice(0, limit ? limit : featuredProducts.length);

    case "deal":
      const dealProducts = finalProducts.filter((single) => single.dealEnd);
      return dealProducts.slice(0, limit ? limit : dealProducts.length);

    case "new":
      const newProducts = finalProducts.filter((single) => {
        return single.new;
      });
      return newProducts.slice(0, limit ? limit : newProducts.length);

    case "popular":
      return (
        finalProducts &&
        finalProducts
          .sort((a, b) => {
            return b.saleCount - a.saleCount;
          })
          .slice(0, limit ? limit : finalProducts.length)
      );

    case "topRated":
      return (
        finalProducts &&
        finalProducts
          .sort((a, b) => {
            return b.rating - a.rating;
          })
          .slice(0, limit ? limit : finalProducts.length)
      );

    case "sale":
      const saleItems =
        finalProducts &&
        finalProducts.filter(
          (single) => single.discount && single.discount > 0
        );
      return saleItems.slice(0, limit ? limit : saleItems.length);

    default:
      return (
        finalProducts &&
        finalProducts.slice(0, limit ? limit : finalProducts.length)
      );
  }
};

// get product discount price
export const getDiscountPrice = (
  price,
  discount,
  priceRangeArray,
  selectedVariant,
  quantity
) => {
  try {
    if (
      selectedVariant &&
      selectedVariant.priceRangeArray &&
      selectedVariant.priceRangeArray.length > 0
    ) {
      return (
        selectedVariant.priceRangeArray.reduce(
          (acc, range) =>
            parseInt(range.from) <= quantity &&
            // True if range max is not defined
            (range.to === "MAX" || parseInt(range.to) >= quantity)
              ? parseFloat(range.price)
              : acc,
          price
        ) || 0
      );
    } else if (priceRangeArray) {
      return (
        priceRangeArray.reduce(
          (acc, range) =>
            parseInt(range.from) <= quantity &&
            (range.to === "MAX" || parseInt(range.to) >= quantity)
              ? parseFloat(range.price)
              : acc,
          price
        ) || 0
      );
    } else {
      return (
        (discount && discount > 0 ? price - price * (discount / 100) : price) ||
        0
      );
    }
  } catch (error) {
    return 0;
  }
};

// get product cart quantity
export const getProductCartQuantity = (cartItems, selectedVariant) => {
  let qty = 0;

  let productInCart = cartItems.filter(
    (cartItem) => cartItem?.selectedVariant?.id === selectedVariant?.id
  )[0];

  if (productInCart) {
    qty = productInCart.quantity;
  }

  return qty;
};

//get products based on category
export const getSortedProducts = (products, sortType, sortValue) => {
  if (products && sortType && sortValue) {
    if (sortType === "category") {
      return products.filter(
        (product) =>
          product.category.filter((single) => single === sortValue)[0]
      );
    } else if (sortType === "tag") {
      return products.filter(
        (product) => product.tag.filter((single) => single === sortValue)[0]
      );
    }
    // else if (sortType === "color") {
    //   return products.filter(
    //     (product) =>
    //       product.variation &&
    //       product.variation.filter((single) => single.color === sortValue)[0]
    //   );
    // }
    // else if (sortType === "size") {
    //   return products.filter(
    //     (product) =>
    //       product.variation &&
    //       product.variation.filter(
    //         (single) =>
    //           single.size.filter((single) => single.name === sortValue)[0]
    //       )[0]
    //   );
    // }
    else if (sortType === "filterSort") {
      let sortProducts = [...products];
      if (sortValue === "default") {
        return sortProducts;
      }
      if (sortValue === "priceHighToLow") {
        return sortProducts.sort((a, b) => {
          return b.price - a.price;
        });
      }
      if (sortValue === "priceLowToHigh") {
        return sortProducts.sort((a, b) => {
          return a.price - b.price;
        });
      }
    } else if (sortType && sortValue) {
      const urlParams = new URLSearchParams(window.location.search);
      const category = urlParams.get("category");
      return products.filter((product) =>
        product.variations
          ? product.category.filter((single) => single === category)[0] &&
            product.variations.reduce((acc, variation) => {
              return variation[sortType] === sortValue ? true : acc;
            }, false)
          : false
      );
    }
  }
  return products;
};

// get individual element
const getIndividualItemArray = (array) => {
  let individualItemArray = array.filter((v, i, self) => {
    return i === self.indexOf(v);
  });
  return individualItemArray;
};

// get individual element object
const getIndividualColorObjectArray = (array) => {
  let individualObjectArray = array.filter((v, i, self) => {
    return (
      i ===
      self.findIndex(
        (t) => t.colorName === v.colorName && t.colorCode === v.colorCode
      )
    );
  });
  return individualObjectArray;
};

// get individual categories
export const getIndividualCategories = (products) => {
  let productCategories = [];
  products &&
    products.map((product) => {
      return (
        product.category &&
        product.category.map((single) => {
          return productCategories.push(single);
        })
      );
    });
  var individualProductCategories = [];
  var obj = {};
  var newArr = [];

  function countItems(productCategories, val) {
    var count = 0,
      i;
    while ((i = productCategories.indexOf(val, i)) != -1) {
      ++count;
      ++i;
    }
    return count;
  }

  productCategories.forEach((item) => {
    let count = countItems(productCategories, item);
    var objValues = Object.values(obj);
    newArr.push(objValues[0]);
    if (newArr.indexOf(item) !== -1) return;
    obj = {
      name: item,
      count: count,
    };
    individualProductCategories.push(obj);
  });
  return individualProductCategories;
};

// get individual tags
export const getIndividualTags = (products) => {
  let productTags = [];
  products &&
    products.map((product) => {
      return (
        product.tag &&
        product.tag.map((single) => {
          return productTags.push(single);
        })
      );
    });
  const individualProductTags = getIndividualItemArray(productTags);
  return individualProductTags;
};

// get individual colors
export const getIndividualColors = (products) => {
  let productColors = [];
  products &&
    products.map((product) => {
      return (
        product.variation &&
        product.variation.map((single) => {
          return productColors.push({
            colorName: single.color,
            colorCode: single.colorCode,
          });
        })
      );
    });
  const individualProductColors = getIndividualColorObjectArray(productColors);
  return individualProductColors;
};

// get individual sizes
export const getProductsIndividualSizes = (products) => {
  let productSizes = [];
  products &&
    products.map((product) => {
      return (
        product.variation &&
        product.variation.map((single) => {
          return single.size.map((single) => {
            return productSizes.push(single.name);
          });
        })
      );
    });
  const individualProductSizes = getIndividualItemArray(productSizes);
  return individualProductSizes;
};

// get product individual sizes
export const getIndividualSizes = (product) => {
  let productSizes = [];
  product.variation &&
    product.variation.map((singleVariation) => {
      return (
        singleVariation.size &&
        singleVariation.size.map((singleSize) => {
          return productSizes.push(singleSize.name);
        })
      );
    });
  const individualSizes = getIndividualItemArray(productSizes);
  return individualSizes;
};

export const setActiveSort = (e) => {
  const filterButtons = document.querySelectorAll(
    ".widget__categories button, .widget__sizes button, .widget__colors button, .widget__tags button"
  );
  filterButtons.forEach((item) => {
    item.classList.remove("active");
  });
  e.currentTarget.classList.add("active");
};

export const setActiveLayout = (e) => {
  const gridSwitchBtn = document.querySelectorAll(".products-view button");
  gridSwitchBtn.forEach((item) => {
    item.classList.remove("active");
  });
  e.currentTarget.classList.add("active");
};
